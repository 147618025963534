// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~dropify/src/sass/dropify';
@import '~select2/src/scss/core';

body {
    padding-top: 70px;
}

h2,
.h2 {
    font-size: 42px;
    line-height: 48px;
    font-weight: 600;

    &.max-600 {
        max-width: 600px;
    }

    @media (max-width: 768px) {
        font-size: 30px;
        line-height: 34px;
    }
}

h3,
.h3 {
    font-size: 22px;
}

.btn-sm {
    width: 25px;
    height: 25px;
}

.nav-link,
.nav-link:active,
.nav-link:focus {
    color: #fff;
}

input[type='checkbox']:checked {
    background-color: $primary;
}

.dropify-wrapper .dropify-message span.file-icon::before {
    font-size: 50px;
}
.dropify-wrapper .dropify-message span.file-icon {
    font-size: 20px;
}

.select2-results__option[aria-disabled=true] {
	display: none;
}

.select2-container--default .select2-results__option--highlighted,
.select2-container--default .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
	background-color: $primary;
}

.tox-notification.tox-notification--in.tox-notification--warning, .tox-statusbar__branding {
    display: none !important;
}
